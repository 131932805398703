import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  DownloadCloud,
  Eye,
  HelpCircle,
  Search,
  Sliders,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import PowerConsumptionChart from "../Charts/PowerConsumptionChart";
import axios from "axios";
import usePalette from "../../../hooks/usePalette";
import SingleEmissionModal from "../../Sustainability/ESG_Emissions/SingleEmissionModal";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import ReactApexChart from "react-apexcharts";
import DatePicker from 'react-datepicker'
import html2pdf from 'html2pdf.js'; // Import the html2pdf library
import { parseISO, startOfMonth, startOfYear } from "date-fns";
import EmissionsChart from "../../Sustainability/ESG_Emissions/EmissionsChart";

const ESGSection = ({ assetData, ESGContentLoaded }) => {

  const navigate = useNavigate();
  const [powerData, setPowerData] = useState([]);
  const { t, i18n } = useTranslation();
  const palette = usePalette();

  const [assetEcoData, setAssetEcoData] = useState();
  // ESG Simulator
  const [simulated_kWh, set_simulated_kWh] = useState(0);
  const [simulated_emissions, set_simulated_emissions] = useState(0);
  const [simulated_emissions_average, set_simulated_emissions_average] = useState(0);
  const [emissionData, setEmissionData] = useState();
  const [emissionCalculation, setEmissionCalculation] = useState()
  const [emissionClustered, setEmissionClustered] = useState()
  const [loading, setLoading] = useState();
  const [showSingleEmissionModal, setShowSingleEmissionModal] = useState();
  const [selectedEmission, setSelectedEmission] = useState()

  const startDate = startOfYear(new Date()) // new Date(new Date() - 12 * 60 * 60 * 1000)
  const now = new Date()

  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState();
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState();

  // const [showESGConfig, setShowESGConfig] = useState(false);

  useEffect(() => {

    // Calculate initial kWh based on current time of day
    const calculateInitialKWh = () => {
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const elapsedSeconds = (now - startOfDay) / 1000; // Convert milliseconds to seconds
      const energyConsumptionRatePerSecond = 0.0097;
      return elapsedSeconds * energyConsumptionRatePerSecond;
    };

    const calculateEmissionComparisons = () => {
      const householdAvgEmissions = 877 * 0.233;  // Avg household monthly kWh and CO2 factor
      return (calculateInitialKWh() * 0.233 / householdAvgEmissions);
    }

    // Set initial kWh
    set_simulated_kWh(calculateInitialKWh());
    set_simulated_emissions(calculateInitialKWh() * 0.434);
    set_simulated_emissions_average(calculateEmissionComparisons());

    // Update kWh every second

    const interval = setInterval(() => {
      set_simulated_kWh((prevKWh) => prevKWh + 0.0097);
      set_simulated_emissions(calculateInitialKWh() * 0.434);
      set_simulated_emissions_average(calculateEmissionComparisons());
      console.log("Ran simulation")
    }, 2500);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    if (ESGContentLoaded) {

      axios
        .get("/webservice/assets/" + assetData?.id + "/eco")
        .then(function (response) {
          // handle success
          console.log(response.status, "/eco");
          setAssetEcoData(response.data)

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      checkAssetEmissions()

      // calculateAssetEmissions()

      getEmissionsClustered()

      axios
        .get("/webservice/assets/" + assetData?.id + "/clustered_power_usage", {
          params: {
            "period_start": new Date(new Date() - 24 * 60 * 60 * 1000 * 14)?.toISOString(),
            "clustering_unit": "1 day"
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/clustered_power_usage");
          setPowerData(response.data.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)))
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [ESGContentLoaded, assetData?.id]);

  const EmissionsCalculator = ({ kWh }) => {
    const [emissions, setEmissions] = useState({});
    const [householdComparison, setHouseholdComparison] = useState('');

    useEffect(() => {
      // const interval = setInterval(() => {
      calculateEmissions()
      //   console.log("Ran simulation()")
      // }, 1000);

      // // Cleanup on component unmount
      // return () => clearInterval(interval);
    }, []);

    const calculateEmissions = () => {
      const electricityCO2 = kWh * 0.233;  // Example factor
      const drivingCO2 = (kWh / 0.6) * 0.242;
      const heatingCO2 = (kWh / 8) * 2.93;

      const householdAvgEmissions = 877 * 0.233;  // Avg household monthly kWh and CO2 factor
      const householdComparison = (electricityCO2 / householdAvgEmissions * 100)?.toFixed(0);

      setEmissions({
        electricity: electricityCO2?.toFixed(2),
        driving: drivingCO2?.toFixed(2),
        heating: heatingCO2?.toFixed(2)
      });
      setHouseholdComparison(
        `Today's CO2 emissions alone are equivalent to ${householdComparison}% of the average monthly household electricity emissions.`
      );
    };

    return (
      <div>
        <h1>CO2 Emissions Calculator</h1>
        <div>
          {/* <p>Electricity CO2 Emissions: {emissions?.electricity} kg</p>
          <p>Driving CO2 Emissions: {emissions?.driving} kg</p>
          <p>Heating CO2 Emissions: {emissions?.heating} kg</p> */}
          <p>{householdComparison}</p>
        </div>
      </div>
    );
  }

  useEffect(() => {

    calculateAssetEmissions()

  }, [PeriodEndDatePicker]);

  function checkAssetEmissions() {

    setLoading(true)

    axios
      .get("/assets/" + assetData?.id + "/emissions")
      .then(function (response) {
        // handle success
        console.log(response.status, "/emissions", response.data);
        setEmissionData(response.data);
        setLoading(false)
      })
      .catch(function (error) {
        // handleError(error);
        console.log(error);
        setLoading(false)
      })
  }

  function calculateAssetEmissions() {

    axios
      .get("/assets/" + assetData?.id + "/emissions/calculation", {
        params: {
          period_start: PeriodStartDatePicker?.toISOString().replace('Z', '') || null,
          period_end: PeriodEndDatePicker?.toISOString().replace('Z', '') || null
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/emissions/calculation", response.data);
        setEmissionCalculation(response.data);
      })
      .catch(function (error) {
        // handleError(error);
        console.log(error);
      })
  }



  function getEmissionsClustered() {
    axios
      .get("/assets/" + assetData?.id + "/emissions/clustered", {
        params: {
          period_start: PeriodStartDatePicker?.toISOString().replace('Z', '') || null,
          period_end: PeriodEndDatePicker?.toISOString().replace('Z', '') || null,
          clustering_unit: "1 month",
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/emissions/clustered", response.data);
        setEmissionClustered(response.data);

        setPeriodStartDatePicker(response.data?.length > 0 ? parseISO(response.data[0]?.interval_start) : null)
        setPeriodEndDatePicker(response.data?.length > 0 ? parseISO(response.data?.[response.data?.length - 1]?.interval_start) : null)
      })
      .catch(function (error) {
        // handleError(error);
        console.log(error);
      })
  }

  function getMonthlyComsumption(emission) {

    var factor = 1
    switch (emission?.period_type) {
      case "daily":
        factor = 30
        break;
      case "yearly":
        factor = 1 / 12
        break;
      default:
        factor = 1
    }

    return emission?.amount * emission?.co_two_kg_e_per_unit * factor
  }

  const getChartData = () => {
    let chartData = [];

    emissionData?.forEach((element) => {
      chartData.push({
        title: element?.title,
        value: getMonthlyComsumption(element),
      });
    });

    return chartData;
  };

  // Prepare chart data for ApexCharts
  const pieChartData = getChartData();

  const sortedPieChartData = pieChartData.sort((a, b) => b.value - a.value);

  const pieChartLabels = sortedPieChartData.map((item) => item.title);
  const pieChartSeries = sortedPieChartData.map((item) => item.value);

  const chartOptions = {
    labels: pieChartLabels,
    legend: {
      position: 'left',
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} kg CO2e`; // Format with units and commas
        }
      }
    },
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       labels: {
    //         show: false,
    //         total: {
    //           show: true,
    //           label: 'Total',
    //           formatter: function (w) {
    //             return w.globals.seriesTotals.reduce((a, b) => a + b, 0).toLocaleString() + ' kg CO2e';
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
  };

  function getFormattedCO2Value(value) {
    if (value > 1000) {
      return <>{(value / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"} t CO<sub>2</sub>e</>
    } else {
      return <>{(value)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) || "-"} kg CO<sub>2</sub>e</>
    }
  }

  function downloadReport() {
    const element = document.getElementById('report-content');

    const opt = {
      margin: [0, 0, 0, 0],
      filename: 'ESG_Report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };

    // Add a slight delay to ensure the chart is fully rendered before generating the PDF
    setTimeout(() => {
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 1, pdf.internal.pageSize.getHeight() - 0.5); // Add page numbers at the bottom
          }
        })
        .save();
    }, 500);  // 500ms delay to ensure everything is rendered before capturing
  }

  return (<div style={{ background: palette["gray-200"], padding: "20px" }} id="report-content">

    <style>
      {`
          @media print {
            .pagebreak { page-break-before: always; } /* Force page breaks */
          }

          .pdf-chart { 
            height: 400px !important; /* Ensure chart height is sufficient for PDF */
          }
        `}
    </style>

    {showSingleEmissionModal && <SingleEmissionModal
      desired_emission_id={selectedEmission}
      handleClose={() => {
        setShowSingleEmissionModal(null)
        checkAssetEmissions()
      }}
    />}

    <Row className="mt-3 mb-4">
      <Col>
        <div className="h3">{t("Environmental Social Governance (ESG) Data")}</div>
      </Col>
      <Col xs="auto" className="ms-auto text-end mt-n1">
        {/* <Button
          onClick={() => setShowESGConfig(true)}
          variant="outline-primary"
          size="sm"
          className="shadow-sm me-1">
          <Sliders className="feather" /> {!isMobile && t("ESG Configuration")}
        </Button> */}
        <Button
          onClick={() => downloadReport()}
          variant="outline-primary"
          size="sm"
          className="shadow-sm me-1">
          <DownloadCloud className="feather" /> {!isMobile && t("Report")}
        </Button>
      </Col>
    </Row>

    {localStorage.getItem("tenant_id") !== "tWMPWtl" && <div><Row>
      <Col md="6" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Emissions")}</h5>
              </Col>

            </Row>
            <span className="h1 d-inline-block mt-1 mb-4">{getFormattedCO2Value(emissionCalculation?.co_two_total)}</span>
          </Card.Body>
          <Card.Footer>
            <div className="mb-0">
              <span className="text-muted">
                <Form.Group className="mb-3">
                  <Form.Label>{t("Date range")}</Form.Label><br />
                  <InputGroup className='flex-nowrap rounded pe-5'>
                    <Form.Control
                      as={DatePicker}
                      selectsStart
                      startDate={PeriodStartDatePicker}
                      endDate={PeriodEndDatePicker}
                      selected={PeriodStartDatePicker}
                      locale={i18n.language === "de" ? de : undefined}
                      onChange={(date) => setPeriodStartDatePicker(date || new Date())}  // Fallback to current date
                      dateFormat={'MMM d, yyyy'}
                      className='rounded-0'
                      placeholderText='From date'
                    />
                    <Form.Control
                      as={DatePicker}
                      selectsEnd
                      startDate={PeriodStartDatePicker}
                      endDate={PeriodEndDatePicker}
                      selected={PeriodEndDatePicker}
                      locale={i18n.language === "de" ? de : undefined}
                      minDate={PeriodStartDatePicker}
                      onChange={(date) => setPeriodEndDatePicker(date || new Date())}  // Fallback to current date
                      dateFormat={'MMM d, yyyy'}
                      className='rounded-0'
                      placeholderText='To date'
                    />
                    <Button
                      onClick={() => {
                        // calculateAssetEmissions() // Brauchen wir hier nicht -> siehe useEffect
                        getEmissionsClustered()
                      }}
                      variant="outline-primary"
                      // size="sm"
                      className="shadow-s"
                    >
                      <Search className="feather" />
                    </Button>
                  </InputGroup>
                </Form.Group>
              </span>
            </div>
          </Card.Footer>
        </Card>
      </Col>
      <Col md="6" xs="6" className="d-flex">
        <Card className="flex-fill">

          <Card.Header>
            <Card.Title>{t("Emission Data Breakdown")}</Card.Title>
            <Card.Subtitle>{t("Last 30 days (ø)")}</Card.Subtitle>
          </Card.Header>
          <Card.Body>
            {pieChartData.length > 0 ? (
              <ReactApexChart
                options={chartOptions}
                series={pieChartSeries}
                type="pie"
                height="150"
              />
            ) : (
              <p>No data available for the chart.</p>
            )}
          </Card.Body>

          {/* <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">
                  {
                    (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                      ? t("Today's emission is about")
                      : t("Power per Unit")
                  }
                </h5>
              </Col>
            </Row>

            <span className="h1 d-inline-block mt-1 mb-4"></span>

            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
            </div>
          </Card.Body> */}
        </Card>
      </Col>
    </Row>


      <Row>
        <Col>
          {emissionClustered ? (
            <EmissionsChart emissionData={emissionClustered} isLoading={loading} />
          ) : (
            <p>{t("No emissions data available.")}</p>
          )}
        </Col>
      </Row>

      <Row>
        <Col className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Row>
                <Col>
                  <div className="d-flex">
                    <Card.Title tag="h5">{t("Asset Footprint Reporting (PCAF)")}</Card.Title>

                    <OverlayTrigger
                      placement='bottom'
                      // style={{ width: "300px" }}
                      overlay={<Popover>
                        <Popover.Header as='h3'>PCAF</Popover.Header>
                        <Popover.Body>
                          <div className="mb-4">
                            Zur Ermittlung und Berechnung der ausgestoßenen Emissionen verleaster Vermögenswerte gibt es im Wesentlichen zwei anerkannte Standards:
                            <br /><strong>1.	Das Greenhouse Gas Protocol (GHG)</strong> für alle Unternehmen
                            <br /><strong>2.	Partnership for Carbon Accounting Financials (PCAF)</strong> speziell für Banken und Finanzdienstleister
                            <br /><br />
                            Der Fokus des <strong>PCAF</strong> liegt auf den Treibhausgasemissionen, die <strong>während der Finanzierungsdauer</strong> entstehen. Dabei werden die Emissionen nur anteilig in Höhe des verbleibenden Finanzierungsvolumens ausgewiesen. PCAF berücksichtigt somit ausschließlich die Emissionen, die von der Finanzierungsgesellschaft in der Nutzungsphase beeinflusst werden können, und dies auch nur in der Höhe des finanzierten Anteils (Attribution Factor).
                          </div>
                        </Popover.Body>
                      </Popover>}
                    >
                      <HelpCircle className="feather ms-2" />
                    </OverlayTrigger>
                  </div>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <Button variant="outline-primary" size="sm" onClick={() => setShowSingleEmissionModal(true)} >
                    {t("Add Emission")}
                  </Button>
                </Col>
              </Row>
            </Card.Header>

            <Card.Body>



              {loading
                ? <LoadingSpinner withText={t("Loading")} />
                : <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">{t("Title")}</th>
                      <th scope="col">{t("Category")}</th>
                      <th scope="col">{t("Amount")}</th>
                      <th scope="col">{t("kg CO\u2082e per unit")}</th>
                      <th scope="col">{t("Interval")}</th>
                      <th scope="col">{t("Letzte 30 Tage (ø)")}</th>
                    </tr>
                  </thead>
                  <tbody>{

                    emissionData?.length > 0
                      ? // If data found

                      emissionData?.map((emission) => {

                        return (
                          <tr key={emission?.id}
                            style={{ cursor: "pointer" }} onClick={() => {
                              setSelectedEmission(emission?.id)
                              setShowSingleEmissionModal(true)
                            }
                            }
                          >
                            <td><strong>{emission?.title}</strong></td>
                            <td>{t(emission?.category)}</td>
                            <td>{emission?.amount?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} {emission?.unit}</td>
                            <td>{emission?.co_two_kg_e_per_unit.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 })} kg / {emission?.unit}</td>
                            <td>{t(emission?.period_type)}</td>
                            <td>{(getMonthlyComsumption(emission))?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} kg CO<sub>2</sub>e</td>

                          </tr >
                        );
                      })
                      : // If no data found
                      <tr>
                        <td colSpan="2">
                          {t("No data found.")}
                        </td>
                      </tr>
                  }
                  </tbody>
                </Table>
              }</Card.Body>
          </Card>

        </Col>
      </Row></div>
    }



    <Row>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Power Consumption")}</h5>
              </Col>
            </Row>
            {(assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk") ? <span className="h1 d-inline-block mt-1 mb-4">{(simulated_kWh).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} kWh</span> : assetEcoData?.power_usage_current_day !== 0
              ? <span className="h1 d-inline-block mt-1 mb-4">{(assetEcoData?.power_usage_current_day / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })} kWh</span>
              : <span className="h1 d-inline-block mt-1 mb-4">{((assetData?.co2_per_use * assetData?.usage_section?.usages_today) / 1000).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kg"}</span>}
            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">
                  {
                    (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                      ? t("Today's emission is about")
                      : t("Power per Unit")
                  }
                </h5>
              </Col>
            </Row>

            {
              (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                ? <span className="h1 d-inline-block mt-1 mb-4">
                  {(simulated_emissions_average)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'percent',
                    // currency: "EUR",
                    maximumFractionDigits: 2,
                  })
                  }</span>
                : <span className="h1 d-inline-block mt-1 mb-4">{(assetEcoData?.power_per_use_current_day / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })} kWh/{t("unit")}</span>
            }
            <div className="mb-0">
              {
                (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                  ? <span className="text-muted">{t("of a U.S. household's monthly electricity consumption")}</span>
                  : <span className="text-muted">{t("Today")}</span>
              }

            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Emissions")}</h5>
              </Col>
            </Row>

            <span className="h1 d-inline-block mt-1 mb-4">ca. {
              (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                ? (simulated_emissions)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })
                : (assetEcoData?.power_usage_current_day * assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })
            } kg CO<sub>2</sub>e</span>
            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
              {/* <CompensationModal buttonSize="small" offsettable_co2e={assetData?.power_consumption?.all_time / 1000 * assetData?.co2_kg_per_wh} /> */}
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="3" xs="6" className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Electricity Costs")}</h5>
              </Col>
            </Row>
            <span className="h1 d-inline-block mt-1 mb-4">
              ca. {
                (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk")
                  ? (simulated_kWh * 0.25)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: "EUR",
                    maximumFractionDigits: 2,
                  })
                  : (assetEcoData?.power_usage_current_day / 1000 * 0.25)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: "EUR",
                    maximumFractionDigits: 2,
                  })
              }</span>
            <div className="mb-0">
              <span className="text-muted">{t("Today")}</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>

    {
      (assetData?.id !== "aOVeu55" && assetData?.id !== "al4VVdk") && <Row>
        <Col>
          <PowerConsumptionChart PowerData={powerData} />
        </Col>
      </Row>
    }

    {/* {
      (assetData?.id === "aOVeu55" || assetData?.id === "al4VVdk") && <Row>
        <Col>
          <EmissionsCalculator kWh={simulated_kWh} />
        </Col>
      </Row>
    } */}


  </div>)
}

export default ESGSection;
