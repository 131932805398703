import { format, formatDuration, intervalToDuration, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const OperatingTimesChart = (props) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  function getData() {
    var barData = []
    props?.operatingTimes?.forEach(entry => {
      barData.push({
        name: t(entry?.state_name),
        data: entry?.clustered_operating_times?.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)).map((e) => { return e.seconds }),
      })
    });

    return barData
  }

  const data = getData();

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  function determineChartColors() {
    return props?.operatingTimes[2]?.state_name === "Running (Input Pressure >= 20 bar)"
      ? [
        palette["gray-500"],
        palette.warning,
        palette.success,
      ]
      : [
        palette["gray-500"],
        palette.warning,
        palette.danger,
        palette.success,
        palette.primary,
      ]
  }

  const dynamicColors = determineChartColors();

  const firstNonEmptyCluster = props.operatingTimes.find(item => item?.clustered_operating_times?.length > 0);

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    labels: firstNonEmptyCluster
      ? firstNonEmptyCluster.clustered_operating_times
        .sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start))
        .map((e) => format(parseISO(e.interval_start), "E, d MMM yyyy", { locale: i18n.language === "de" ? de : null }))
      : [],
    xaxis: {
      // type: "time",
      // max: 100,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis: {
      title: {
        text: t("State in Seconds"),
      },
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
        formatter: (value) => { return value.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, }) },
      },
    },
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatDuration(intervalToDuration({ start: 0, end: val * 1000 }), { format: ['days', 'hours', 'minutes', "seconds"], locale: i18n.language === "de" ? de : null });
        },
      },
    },
    colors: dynamicColors,
  };

  return (

    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5">{t("Operating Times")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading === true ?

            showLoadingSpinner() :

            props?.operatingTimes?.length === 0

              ?

              <h6 className="card-subtitle text-muted">
                {t("No operating times available.")}
              </h6>

              :

              <div className="chart">
                <Chart options={options} series={data} type="bar" height="350" />
              </div>
        }
      </Card.Body>
    </Card>
  );
};

export default OperatingTimesChart;
