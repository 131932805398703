import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Button, Col, Dropdown, Form, InputGroup, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import NotyfContext from "../../contexts/NotyfContext";
import AssetCategories from "../Helper/AssetCategories";
import { de } from "date-fns/locale";
import { getCoreBaseURL } from "../../config";

const EditAssetModal = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const [data, setData] = useState([]);
  const [assetFinancials, setAssetFinancials] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  useEffect(() => {

    if (openModal) {
      axios
        .get("/assets/" + id)
        .then(function (response) {
          // handle success
          console.log(response.status, "Load asset edit");
          setData(response.data);
          set_co2_kg_per_wh(response.data?.co2_kg_per_wh);
          set_usage_enabled(response.data?.usage_enabled)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/assets/" + id + "/financials")
        .then(function (response) {
          // handle success
          console.log(response.status, "Loaded financials");
          setAssetFinancials(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [openModal]);

  const [timeZones, setTimeZones] = useState([]);
  const [usage_enabled, set_usage_enabled] = useState();

  useEffect(() => {
    // Generate a list of time zones using Intl API
    const zoneNames = new Set();
    const names = Intl.supportedValuesOf('timeZone');
    // console.log("-->", names)
    names.forEach(name => zoneNames.add(name));
    setTimeZones([...zoneNames]);
  }, []);

  var asset_title = data.title;
  var asset_serial = data.serial;
  // var asset_per_use_price = data.per_use_price;
  var asset_description = data.description;
  var asset_invest_price = data.investment_price;
  var iot_gateway_id = data.iot_gateway_id;
  var asset_anomaly_report_instructions = data.anomaly_report_instructions;
  var asset_duration = data.duration_in_months;
  // var asset_monthly_base_fee = data.monthly_base_fee;
  var asset_activiation_date = data.activation_date;
  var asset_operating_hours = data.daily_operating_hours;
  var asset_max_output_per_hour = data.max_output_per_hour;
  var asset_latitude = data.location?.coordinates[0];
  var asset_longitude = data.location?.coordinates[1];
  var timezone = data.timezone;
  var connection_protocol = data?.connection_protocol;
  var sending_interval_minutes = data?.sending_interval_minutes;
  const [co2_kg_per_wh, set_co2_kg_per_wh] = useState();
  var category = data.category;

  // Financials
  var financials_base_price = assetFinancials?.base_price
  var financials_tier = assetFinancials?.tier
  var financials_investment_price = assetFinancials?.investment_price
  var financials_monthly_base_fee = assetFinancials?.monthly_base_fee
  var financials_market_value = assetFinancials?.market_value
  var financials_residual_book_value = assetFinancials?.residual_book_value
  var financials_capon_interest_rate = assetFinancials?.capon_interest_rate
  var financials_capon_rate = assetFinancials?.capon_rate
  var financials_full_interest_rate = assetFinancials?.full_interest_rate
  var financials_price_factors = (assetFinancials?.price_factors?.length === 0 || assetFinancials?.price_factors?.length === 1) ? [
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
    {
      "starting_from": 0,
      "price": 0
    },
  ] : assetFinancials?.price_factors
  var financials_refi_interest_rate = assetFinancials?.refi_interest_rate
  var financials_refi_rate = assetFinancials?.refi_rate
  var financials_starting_fee = assetFinancials?.starting_fee
  // const [financials_price_factors, set_financials_price_factors] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    console.log("----->", asset_latitude, asset_longitude)
    axios
      .put("/assets/" + id, {
        "title": asset_title,
        "serial": asset_serial,
        // "per_use_price": asset_per_use_price,
        "investment_price": asset_invest_price,
        "activation_date": asset_activiation_date,
        "description": asset_description,
        "duration_in_months": asset_duration === "" ? null : asset_duration,
        // "monthly_base_fee": asset_monthly_base_fee,
        "connection_protocol": connection_protocol,
        "sending_interval_minutes": sending_interval_minutes,
        "daily_operating_hours": asset_operating_hours,
        "anomaly_report_instructions": asset_anomaly_report_instructions,
        "max_output_per_hour": asset_max_output_per_hour,
        "co2_kg_per_wh": co2_kg_per_wh,
        "usage_enabled": usage_enabled,
        "iot_gateway_id": iot_gateway_id,
        "category": category === "" ? null : category,
        "timezone": timezone,
        "location": (asset_latitude === "" || asset_latitude === undefined) ? null : {
          "type": "Point",
          "coordinates": [asset_latitude, asset_longitude]
        },
      })
      .then(function (response) {
        // handle success
        navigate("/assets/" + response.data.id);
        console.log(response.status, "Load");

        notyf.open({
          type: "success",
          message: t("Item \"") + asset_title + t("\" updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .put("/assets/" + id + "/financials", {
        "tier": financials_tier,
        "price_factors": financials_price_factors,
        "base_price": financials_base_price,
        "refi_rate": financials_refi_rate,
        "capon_rate": financials_capon_rate,
        "starting_fee": financials_starting_fee,
        "full_interest_rate": financials_full_interest_rate,
        "refi_interest_rate": financials_refi_interest_rate,
        "capon_interest_rate": financials_capon_interest_rate,
        "investment_price": financials_investment_price,
        "monthly_base_fee": financials_monthly_base_fee,
        "market_value": financials_market_value,
        "residual_book_value": financials_residual_book_value
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const [GatewayArray, setGatewayArray] = useState([]);

  useEffect(() => {
    axios
      .get("/iot_gateways/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/gateways");
        setGatewayArray(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi");
        setConnectionProtocolOptions(response.data["components"]["schemas"]["AssetConnectionProtocol"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const loadGateways = () => {
    return GatewayArray.map((gateway) => {
      return (
        <option key={gateway.id} value={gateway.id} selected={gateway.id === iot_gateway_id}>{gateway.serial}</option>
      );
    });
  };

  const [ConnectionProtocolOptions, setConnectionProtocolOptions] = useState([]);

  const ShowConnectionProtocolOptions = () => {
    return ConnectionProtocolOptions.map((item) => {
      return (
        <option key={item} value={item} selected={connection_protocol === item}>{item.toUpperCase()}</option>
      );
    });
  };

  function refreshPage() {
    window.location.reload(false);
  }

  var factors = [];
  function handleChangeP2Produced(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = financials_price_factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["starting_from"] = parseFloat(event.target.value);
    } else {
      factors[event.target.id]["starting_from"] = parseFloat(event.target.value);
    }

    financials_price_factors = factors

    console.log(financials_price_factors);
  }

  function handleChangeP2Factor(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = financials_price_factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["price"] = parseFloat(event.target.value);
    } else {
      factors[event.target.id]["price"] = parseFloat(event.target.value);
    }

    financials_price_factors = factors

    console.log(financials_price_factors);
  }

  const renderRows = () => {
    return financials_price_factors?.map((entry, index) => {
      return (
        <Row key={index}>
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Produced}
                  id={index}
                  type="number"
                  defaultValue={entry.starting_from?.toLocaleString(i18n.language === "de" ? de : "en")}
                  placeholder="0 units" />
                <InputGroup.Text>Units</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>

          {/* <Col md={1}></Col> */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Factor}
                  id={index}
                  type="number"
                  defaultValue={entry.price}
                  placeholder="0 €" />
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      );
    });
  };

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Edit asset")}</Dropdown.Item>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit asset")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Title")}</Form.Label>
                      <Form.Control onChange={event => asset_title = event.target.value} defaultValue={data.title} placeholder="Title" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("Model Number")}</Form.Label>
                      <Form.Control onChange={event => asset_serial = event.target.value} defaultValue={data.serial} placeholder="ABC 123 DEF" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("cap-on Activation Date (start of billing cycle)")}</Form.Label>
                      <Form.Control
                        defaultValue={data.activation_date == null ? null : format(parseISO(data.activation_date), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                        onChange={event => asset_activiation_date = event.target.value}
                        type="date"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Description")}</Form.Label>
                  <Form.Control
                    onChange={event => asset_description = event.target.value}
                    defaultValue={data.description}
                    rows="9"
                    as="textarea"
                    placeholder="Description" />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Duration in months")}</Form.Label>
                  <Form.Control onChange={event => asset_duration = event.target.value} defaultValue={data.duration_in_months} placeholder="60 months" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Maximum Output/h")}</Form.Label>
                  <Form.Control onChange={event => asset_max_output_per_hour = event.target.value} defaultValue={data.max_output_per_hour} placeholder="20/h" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Daily Operating Hours")}</Form.Label>
                  <Form.Control onChange={event => asset_operating_hours = event.target.value} defaultValue={data.daily_operating_hours} placeholder="22 (daily)" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Category")}</Form.Label>
                  <Form.Select name="state" onChange={event => category = event.target.value}>
                    <option value="">{t("-- Select --")}</option>
                    <AssetCategories />
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("cap-on IoT-Gateway")}</Form.Label>
                  <Form.Select name="state" onChange={event => iot_gateway_id = event.target.value}>
                    <option value="">{t("-- Select --")}</option>
                    {loadGateways()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("kg CO\u2082 per Wh")} ({t("Plan")})</Form.Label>
                  <Form.Select onChange={(e) => set_co2_kg_per_wh(e.target.value)}>
                    <option value={0.00042} selected={data?.co2_kg_per_wh === 0.00042}>{t("Strommix")}</option>
                    <option value={0.000025} selected={data?.co2_kg_per_wh === 0.000025} >{t("Ökostrom")}</option>
                    <option value={0.001} selected={data?.co2_kg_per_wh === 0.001}>{t("Kohle")}</option>
                    <option value={0.0005} selected={data?.co2_kg_per_wh === 0.0005} >{t("Gas")}</option>
                    <option value={0.00065} selected={data?.co2_kg_per_wh === 0.00065} >{t("Öl")} </option>
                    <option value={0.00002} selected={data?.co2_kg_per_wh === 0.00002} >{t("Kernkraft")} </option>
                    <option value={0.000024} selected={data?.co2_kg_per_wh === 0.000024} >{t("Wasser")}</option>
                    <option value={0.000011} selected={data?.co2_kg_per_wh === 0.000011} >{t("Wind")} </option>
                    <option value={0.000045} selected={data?.co2_kg_per_wh === 0.000045} >{t("Solar")} </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("kg CO\u2082 per Wh")}</Form.Label>
                  <Form.Control value={co2_kg_per_wh} onChange={(e) => set_co2_kg_per_wh(e.target.value)} placeholder="0.00042 kg CO\u2082" />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("kg CO\u2082 per kWh")}</Form.Label>
                  <Form.Control disabled value={(co2_kg_per_wh) * 1000} placeholder="0.42 kg CO\u2082" />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("connection_protocol")}</Form.Label>
                  <Form.Select name="state" onChange={event => connection_protocol = event.target.value}>
                    <option value="">{t("-- Select --")}</option>
                    {ShowConnectionProtocolOptions()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("sending_interval_minutes")}</Form.Label>
                  <Form.Control onChange={event => sending_interval_minutes = event.target.value} defaultValue={sending_interval_minutes} />
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("usage_enabled")}</Form.Label>
                  <Form.Check
                    checked={usage_enabled}
                    onChange={() => set_usage_enabled(!usage_enabled)}
                    label={t("Enabled")}
                    type="checkbox" />
                </Form.Group>
              </Col>

            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Anomaly Report-Instructions")}</Form.Label>
                  <Form.Control
                    onChange={event => asset_anomaly_report_instructions = event.target.value}
                    defaultValue={data?.anomaly_report_instructions}
                    rows="9"
                    as="textarea"
                    placeholder="" />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Latitude")}</Form.Label>
                  <Form.Control onChange={event => asset_latitude = event.target.value} defaultValue={data.location?.coordinates[0] || null} placeholder="20.3082" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Longitude")}</Form.Label>
                  <Form.Control onChange={event => asset_longitude = event.target.value} defaultValue={data.location?.coordinates[1] || null} placeholder="51.2980" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Time zone")}</Form.Label>
                  <Form.Select onChange={event => timezone = event.target.value} defaultValue={timezone}>
                    <option>{t("-- Select --")}</option>
                    {timeZones.map(zone => (
                      <option key={zone} value={zone} selected={timezone === zone}>{zone}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {
              UserDataManager.getInstance().isCapOn() && <><br />
                <br />
                <hr />
                <br />
                <Accordion>
                  <Accordion.Item eventKey="0" className="bg-white">
                    <Accordion.Header>{t("Financial Asset Settings")}</Accordion.Header>
                    <Accordion.Body className="bg-white">

                      <Row>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_tier")}</Form.Label>
                            <Form.Select required
                              onChange={event => financials_tier = event.target.value} defaultValue={financials_tier || "-"}>
                              <option>{t("-- Select --")}</option>
                              {/* <option value={0}>{t("Tier 0")}</option> */}
                              <option value={1}>{t("Tier 1 - Pay-per-Use Leasing")}</option>
                              <option value={2}>{t("Tier 2 - Asset-as-a-Service Light")}</option>
                              <option value={3}>{t("Tier 3 - Asset-as-a-Service")}</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_monthly_base_fee")}</Form.Label>
                            <Form.Control onChange={event => financials_monthly_base_fee = event.target.value}
                              defaultValue={financials_monthly_base_fee || "-"}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_investment_price")}</Form.Label>
                            <Form.Control onChange={event => financials_investment_price = event.target.value} defaultValue={financials_investment_price || "-"} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_base_price")}</Form.Label>
                            <Form.Control
                              disabled
                              onChange={event => financials_base_price = event.target.value} defaultValue={financials_base_price || "-"}
                            // onChange={event => set_financials_base_price(event.target.value)} 
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_starting_fee")}</Form.Label>
                            <Form.Control onChange={event => financials_starting_fee = event.target.value} defaultValue={financials_starting_fee || "-"} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_refi_rate")}</Form.Label>
                            <Form.Control onChange={event => financials_refi_rate = event.target.value} defaultValue={financials_refi_rate || "-"} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_capon_rate")}</Form.Label>
                            <Form.Control onChange={event => financials_capon_rate = event.target.value} defaultValue={financials_capon_rate || "-"} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_residual_book_value")}</Form.Label>
                            <Form.Control
                              disabled
                              onChange={event => financials_residual_book_value = event.target.value} defaultValue={financials_residual_book_value || "-"}
                            // onChange={event => set_financials_residual_book_value(event.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_full_interest_rate")}</Form.Label>
                            <Form.Control
                              disabled
                              onChange={event => financials_full_interest_rate = event.target.value} defaultValue={financials_full_interest_rate || "-"}
                            // onChange={event => set_financials_full_interest_rate(event.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_capon_interest_rate")}</Form.Label>
                            <Form.Control
                              disabled
                              onChange={event => financials_capon_interest_rate = event.target.value} defaultValue={financials_capon_interest_rate || "-"}
                            // onChange={event => set_financials_capon_interest_rate(event.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_refi_interest_rate")}</Form.Label>
                            <Form.Control
                              disabled
                              onChange={event => financials_refi_interest_rate = event.target.value} defaultValue={financials_refi_interest_rate || "-"}
                            // onChange={event => set_financials_refi_interest_rate(event.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("financials_market_value")}</Form.Label>
                            <Form.Control
                              disabled
                              onChange={event => financials_market_value = event.target.value} defaultValue={financials_market_value || "-"}
                            // onChange={event => set_financials_market_value(event.target.value)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Accordion>
                          <Accordion.Item eventKey="0" className="bg-white">
                            <Accordion.Header>{t("Pay-per-Use Price Factors")}</Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Starting From Unit")}</Form.Label>
                                  </Form.Group>
                                </Col>
                                {/* <Col md={1}> </Col>*/}
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Unit Price")}</Form.Label>
                                  </Form.Group>
                                </Col>
                              </Row>
                              {renderRows()}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            }
            <br />
            <hr />
            <br />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAssetModal;
