import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { RefreshCw } from "react-feather";
import AssetTable from "./AssetTable";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import AddAssetToContractModal from "./AddAssetToContractModal";
// import RFB from '@novnc/novnc/core/rfb';
import { VncScreen } from 'react-vnc';

const Assets = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setError({ showMessage: false, errorData: null })

    axios
      .get("/webservice/assets/", {
        // params: {
        //   "status": "approved_only",
        // }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/assets");
        // setData([]);
        setData(
          response.data?.sort((a, b) => {
            // First, sort by connection_status
            const statusComparison = a.connection_status.localeCompare(b.connection_status);
            if (statusComparison === 0) {
              // If connection_status is the same, sort by title
              return a?.title?.localeCompare(b?.title);
            }
            return statusComparison;
          })
        );
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }, []);

  // const VNCViewer = ({ vncServerUrl }) => {
  //   useEffect(() => {

  //     const RFB = require('@novnc/novnc/core/rfb').default;

  //     let rfb;
  //     const loadVNC = () => {
  //       try {
  //         rfb = new RFB(document.getElementById('vnc-container'), vncServerUrl, {
  //           credentials: {
  //             password: 'ettc',
  //           },
  //         });
  //         rfb.scaleViewport = true; // Optional: Scale the viewport

  //         rfb.addEventListener('connect', () => console.log('Connected to server'));
  //         rfb.addEventListener('disconnect', (e) => console.log('Disconnected:', e));
  //         rfb.addEventListener('securityfailure', (e) => console.error('Security failure:', e.detail));

  //       } catch (error) {
  //         console.error('Failed to initialize VNC connection:', error);
  //       }
  //     };

  //     loadVNC();

  //     return () => {
  //       if (rfb) {
  //         rfb.disconnect();
  //       }
  //     };
  //   }, [vncServerUrl]);

  //   return <div id="vnc-container" style={{ width: '100%', height: '100%' }} />;
  // };

  //   const ref = useRef();
  //   const RemoteHMISection = () => {
  //     return (<Row>
  //       <Col md="12">
  //         <Row>
  //           <Col md="12" xs="6" className="d-flex">
  //             <Card className="flex-fill">
  //               <Card.Header>
  //                 <Card.Title>
  //                   HMI
  //                 </Card.Title>
  //               </Card.Header>
  //               <Card.Body>
  //                 {/* <VNCViewer vncServerUrl={"wss://gw.cap-on.de/ws/1"} /> */}
  // {/* 
  //                 <VncScreen
  //                   url='wss://gw.cap-on.de/ws/1'
  //                   scaleViewport
  //                   background="#202020"
  //                   style={{
  //                     width: '75vw',
  //                     height: '75vh',
  //                   }}
  //                   ref={ref}
  //                 /> */}
  //               </Card.Body>
  //             </Card>
  //           </Col>

  //         </Row>
  //       </Col>
  //     </Row>)
  //   }

  return (
    <React.Fragment>
      <Helmet title={t("Assets")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Assets")}</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              onClick={() => navigate("/pricing-engine")}
              variant="primary"
              className="shadow-sm me-1"
            >{t("Pricing Engine")}
            </Button> */}
            <AddAssetToContractModal />
            {/* <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button> */}
          </Col>
        </Row>
        {/* <RemoteHMISection /> */}
        <Row>
          <Col>
            <ErrorHandler error={error} />
            {AssetTable(data, null, isLoading)}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Assets;
