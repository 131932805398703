import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import { getCoreBaseURL } from "../../config";
import NotyfContext from "../../contexts/NotyfContext";
import msteams from "../../assets/icons/msteams.png";

const SingleWatchTowerModal = ({ desired_watchtower_id, selected_asset_id, desired_asset_metric, desired_iot_node, handleClose, ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  // Request Params for form
  const [WatchTowerData, setWatchTowerData] = useState();
  const [watchtowerExists, setWatchtowerExists] = useState(false);
  const [WatchtowerMetricInfo, setWatchtowerMetricInfo] = useState();
  const [WatchtowerNodeInfo, setWatchtowerNodeIdInfo] = useState();

  const [threshold, set_threshold] = useState();
  const [comparison_mode, set_comparison_mode] = useState();
  const [msteams_channel, set_msteams_channel] = useState();
  const [send_emails, set_send_emails] = useState();
  const [severity, set_severity] = useState();

  var SwaggerComparisonModeArray = ["higher", "lower", "equals", "unequals"];
  var SwaggerSeverityLevelArray = ["info", "warning", "critical",]; //, "other"
  const [thresholdError, setThresholdError] = useState("");

  const handleThresholdChange = (e) => {
    const value = e.target.value;
    // Check if the input contains a comma
    if (value.includes(",")) {
      setThresholdError(t("Threshold cannot contain a comma. Please use a dot."));
    } else {
      setThresholdError(""); // Clear error if valid
      set_threshold(parseFloat(value)); // Update state with parsed value
    }
  };

  useEffect(() => {

    setWatchTowerData([])

    // if (show) {

    console.log("Showing Watch Tower Modal: ", desired_asset_metric, desired_iot_node, selected_asset_id)

    if (desired_watchtower_id) {

      // watchtower exists and should be edited

      setWatchtowerExists(true)

      axios
        .get("/watchtower/" + desired_watchtower_id)
        .then(function (response) {
          // handle success
          console.log(response.status, "/watchtower");
          setWatchTowerData(response.data);

          if (response.data?.iot_node_id) {
            // GET iot_node_id / id -> Für Darstellung
            console.log("Editing a watchtower of an iot_node_id")

            axios
              .get("/assets/" + selected_asset_id + "/iot_nodes/" + response.data?.iot_node_id)
              .then(function (response) {
                // handle success
                console.log(response.status, "/iot_nodes");
                setWatchtowerNodeIdInfo(response.data);
              })
              .catch(function (error) {
                // handle error
                console.log(error);
              });

          } else if (response.data?.asset_metric_id) {
            // GET metric / id -> Für Darstellung
            console.log("Editing a watchtower of an asset metric")
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

    } else {

      // watchtower does not exist
      setWatchtowerExists(false)
    }
    // }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    // Ensure there's no threshold error before submitting
    if (thresholdError) {
      return;
    }

    if (watchtowerExists) {

      var data = {
        // "asset_metric_id": desired_asset_metric_id || null,
        // "iot_node_id": desired_iot_node_id || null,
        "threshold": threshold,
        "comparison_mode": comparison_mode,
        "level": severity,
        "msteams_channel": msteams_channel,
        "alert_current_user": true,
        "send_emails": send_emails
        // "users_to_alert": [
        //   UserDataManager.getInstance().getUserData().id
        // ]
      }

      axios
        .put("/watchtower/" + desired_watchtower_id, data)
        .then(function (response) {
          // handle success
          console.log(response.status, "Updated watchtower");

          notyf.open({
            type: "success",
            message: t("Watch Tower updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {

      var dataNew = {
        "asset_id": selected_asset_id,
        "asset_metric_id": desired_asset_metric?.asset_metric_id || null,
        "iot_node_id": desired_iot_node?.iot_node_id || desired_iot_node?.id || null,
        "threshold": threshold,
        "comparison_mode": comparison_mode,
        "level": severity,
        "msteams_channel": msteams_channel,
        "alert_current_user": true,
        "send_emails": send_emails
        // "users_to_alert": [
        //   UserDataManager.getInstance().getUserData().id
        // ]
      }

      axios
        .post("/watchtower/", dataNew)
        .then(function (response) {
          // handle success
          console.log(response, "Added watchtower");

          notyf.open({
            type: "success",
            message: t("Watch Tower added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  return (
    <Modal size="ms" show style={{ zIndex: 1060 }} onHide={() => handleClose()}>
      <Modal.Header closeButton>{watchtowerExists ? t("Edit Watch Tower") : t("Add Watch Tower")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          {/* {!asset_id && <>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Asset")}</Form.Label>
                  <Form.Select
                    name="state"
                    id="asset_changer"
                    required
                    onChange={e => set_asset_id(e.target.value)}>
                    <option key={"18u89"} value="">{t("-- Select --")}</option>
                    {loadAssets()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
          </>} */}

          {/* <Row>
              <div>
                <Alert variant="secondary">
                  <div className="alert-message">
                    Zur Zeit sind <strong>cap-on Watchtower</strong> für Sensordaten eines digitalen Zwillings in der Beta-Phase (Abschluss in Q1/2024).
                  </div>
                </Alert>
              </div>
            </Row> */}
          {/* <Row>

            {WatchtowerNodeInfo && <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Single IoT-data point")}</Form.Label>
                <Form.Control defaultValue={WatchtowerNodeInfo?.log_type?.technical_name || WatchtowerNodeInfo?.log_type?.display_name || "-"} disabled type="text" />
              </Form.Group>
            </Col>}

            {(WatchTowerData?.asset_metric_id || desired_asset_metric) && <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Metric / KPI")}</Form.Label>
                <Form.Control defaultValue={desired_asset_metric?.metric_title || "-"} disabled type="text" />
              </Form.Group>
            </Col>}

          </Row>
          <Row className="mt-4 mb-4">
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Comparison Mode")}</Form.Label>
                <Form.Select name="state" required onChange={(e) => set_comparison_mode(e.target.value)}>
                  <option key={"1uihzi"} value="">{t("-- Select --")}</option>
                  {
                    SwaggerComparisonModeArray?.map((item) => {
                      return (
                        <option key={item} value={item} selected={item === WatchTowerData?.comparison_mode}>{t(item)}</option>
                      );
                    })
                  }
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Threshold")}</Form.Label>
                <Form.Control
                  id="threshold"
                  required
                  defaultValue={WatchTowerData?.threshold}
                  type="decimal"
                  onChange={handleThresholdChange}
                  placeholder={t("Amount")} />
              </Form.Group>
              {thresholdError && (
                <div className="text-danger mt-1">
                  {thresholdError}
                </div>
              )}
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Severity")}</Form.Label>
                <Form.Select name="state" required onChange={(e) => set_severity(e.target.value)}>
                  <option key={"default"} value="">{t("-- Select --")}</option>
                  {
                    SwaggerSeverityLevelArray?.map(item => {
                      return <option key={item} value={item} selected={item === WatchTowerData?.level}>{t(item)}</option>

                    })
                  }
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> */}

          <Row className="mt-2 mb-6">
            <Col md={12}>
              <Form.Group className="mb-3">
                {/* Sentence-style gap text input */}

                <div className="h4 mb-4">{t("Watch Tower Rule")}</div>

                <div className="d-flex flex-wrap align-items-center gap-text-format mb-3" style={{ rowGap: "1rem" }}> {/* rowGap for space between lines */}

                  <span>{t("Notify me when")}</span>&nbsp;

                  {/* Display Watchtower Node Info or IoT Node */}
                  {(WatchtowerNodeInfo || desired_iot_node) && (
                    <span className="mx-1">
                      <strong>
                        {t(WatchtowerNodeInfo?.log_type?.technical_name ||
                          WatchtowerNodeInfo?.log_type?.display_name ||
                          desired_iot_node?.log_type?.technical_name ||
                          desired_iot_node?.log_type?.display_name ||
                          "-")}
                      </strong>
                    </span>
                  )}

                  {/* Display Metric / KPI if available */}
                  {(WatchTowerData?.asset_metric_id || desired_asset_metric) && (
                    <span className="mx-1">
                      <strong>{desired_asset_metric?.metric_title || "-"}</strong>
                    </span>
                  )}

                  {/* Comparison Mode Dropdown */}
                  <Form.Select
                    className="mx-2"
                    name="comparisonMode"
                    required
                    onChange={(e) => set_comparison_mode(e.target.value)}
                    style={{ width: "150px" }}
                  >
                    <option value="">{t("-- Select --")}</option>
                    {SwaggerComparisonModeArray?.map((item) => (
                      <option key={item} value={item} selected={item === WatchTowerData?.comparison_mode}>
                        {t(item)}
                      </option>
                    ))}
                  </Form.Select>

                  {/* Threshold Input */}
                  <Form.Control
                    className="me-2"
                    id="threshold"
                    required
                    defaultValue={WatchTowerData?.threshold}
                    type="number"
                    onChange={handleThresholdChange}
                    placeholder={t("Amount")}
                    style={{ width: "100px" }}
                  />

                  <span>{t("with priority")}</span>&nbsp;

                  {/* Severity Dropdown */}
                  <Form.Select
                    className="mx-2"
                    name="severity"
                    required
                    onChange={(e) => set_severity(e.target.value)}
                    style={{ width: "150px" }}
                  >
                    <option value="">{t("-- Select --")}</option>
                    {SwaggerSeverityLevelArray?.map((item) => (
                      <option key={item} value={item} selected={item === WatchTowerData?.level}>
                        {t(item)}
                      </option>
                    ))}
                  </Form.Select>
                  <span>.</span>
                </div>

                {/* Display Threshold Error if present */}
                {thresholdError && (
                  <div className="text-danger mt-1">
                    {thresholdError}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <hr />
          <Row className="mt-2">
            <Col md={12}>
              <Form.Group>
                <Form.Label>{t("Microsoft Teams Channel")}</Form.Label>
                <img
                  src={msteams}
                  alt="avatar"
                  width="auto"
                  className="ms-2"
                  height={15}
                />
                <Form.Control
                  defaultValue={WatchTowerData?.msteams_channel}
                  disabled={UserDataManager.getInstance().isAdmin() ? false : true}
                  type="text"
                  onChange={(e) => set_msteams_channel(e.target.value)}
                  placeholder="Channel ID" />
              </Form.Group>
            </Col>
            {!watchtowerExists && <Col md={12}>
              <Form.Group >
                <Form.Label>{t("You will be automatically notified in the IoT-Hub")}</Form.Label>
                <Form.Check
                  onChange={(e) => set_send_emails(e.target.checked)}
                  checked={send_emails || WatchTowerData?.send_emails}
                  // type="switch"
                  label={t("Notify me via email, too")}
                />
              </Form.Group>
            </Col>}
          </Row>
          <hr />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant="secondary" onClick={handleClose}>
                {t("Cancel")}
              </Button>{" "}
              <Button variant={"primary"} type="submit" >
                {watchtowerExists ? t("Apply") : t("Create")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body >
    </Modal >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SingleWatchTowerModal;