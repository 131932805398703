import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Breadcrumb,
  Spinner,
  Dropdown,
  Form,
  Accordion,
  OverlayTrigger,
  Popover,
  Tab,
  Nav,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import {
  Activity,
  Clipboard,
  Code,
  Eye,
  HardDrive,
  Info,
  MoreHorizontal, Play, RefreshCw, Save, Settings, Trash,
  Wifi,
} from "react-feather";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../../contexts/NotyfContext";
import UserDataManager from "../../Helper/UserDataManager";
import EditAssetModal from "../AssetModal_Edit";
import OPCUA_Logo from "../../../assets/img/logos/opc_ua.png";
import mqtt_Logo from "../../../assets/img/logos/mqtt.png";
import vnc_Logo from "../../../assets/img/logos/vnc.png";
import modbus_Logo from "../../../assets/img/logos/modbus.png";

import beckhoff_Logo from "../../../assets/img/logos/beckhoff.png";
import SingleIoTDataPointModal from "./SingleIoTDataPointModal";
import { de } from "date-fns/locale";
import ImportFromTemplateModal from "./ImportFromTemplateModal";
import { getGatewayVPNBaseURL } from "../../../config";
import GatewayVPNContentModal from "../../Gateways/GatewayVPNContentModal";
import { formatDistanceToNow, parseISO } from "date-fns";
import { isMobile } from "react-device-detect";
import GatewayOPCUABrowserModal from "../../Gateways/GatewayOPCUABrowserModal";
import SingleTwinStructureModal from "./SingleTwinStructureModal";
import usePalette from "../../../hooks/usePalette";

const AssetDataSetup = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState(null);
  const [configData, setConfigData] = useState(null);
  const [gatewayData, setGatewayData] = useState(null);
  const palette = usePalette();

  const notyf = useContext(NotyfContext);

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [isLoadingRemoteCall, setIsLoadingRemoteCall] = useState(false)
  const [showContentModal, setShowContentModal] = useState()
  const [gatewayVPNResponse, setGatewayVPNResponse] = useState()

  const [opc_endpoint, set_opc_endpoint] = useState()

  const [opcuaEnabled, setOpcuaEnabled] = useState();
  const [vncEnabled, setVncEnabled] = useState();
  const [mqttEnabled, setMqttEnabled] = useState();
  const [adsEnabled, setADSEnabled] = useState();
  const [modbusEnabled, setModbusEnabled] = useState();

  const [security_policy, set_security_policy] = useState()
  const [security_mode, set_security_mode] = useState()
  const [opc_login, set_opc_login] = useState()

  const [VncTrueColor, setVncTrueColor] = useState(false);

  var mqttForce = configData?.mqtt_force;
  var mqtt_port = configData?.mqtt_port;
  var mqtt_broker = configData?.mqtt_broker;
  var vnc_ip = configData?.vnc_ip;

  var opc_user = configData?.opc_user
  var opc_pw = configData?.opc_pw

  useEffect(() => {

    setIsLoading(true)
    setError({ showMessage: false, errorData: null })

    fetchLatestPLC_Node_ID_Data()

    axios
      .get("/webservice/assets/" + id + "/base")
      .then(function (response) {

        // handle success
        console.log(response.status, "/webservice/assets/id/base");

        setAssetData(response.data);
        loadIoTGatewayData(response.data?.iot_gateway_id)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    loadConfigs()

  }, []);

  function loadIoTGatewayData(gatewayId) {
    axios
      .get("/iot_gateways/" + gatewayId)
      .then(function (response) {

        // handle success
        console.log(response.status, "/iot_gateways/id");

        setGatewayData(response.data)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function loadConfigs() {

    axios
      .get("/assets/" + id + "/iot_configs")
      .then(function (response) {

        // handle success
        console.log(response.status, "/assets/iot_config");

        setConfigData(response.data);
        set_opc_login(response.data?.login)
        set_security_mode(response.data?.security_mode)
        set_security_policy(response.data?.security_policy)
        setVncEnabled(response.data.vnc_ip ? true : false)
        set_opc_endpoint(response.data?.opc_endpoint)
        setVncTrueColor(response.data?.vnc_true_color)
        setOpcuaEnabled(response.data.opc_active);
        setMqttEnabled(response.data.mqtt_active);

        setModbusEnabled(response.data.modbus_active);
        setADSEnabled(response.data.ads_active);

        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const ShowLoadingSpinner = () => {
    return (
      <div>
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    let body = {
      "asset_id": id,
      "mqtt_active": mqttEnabled,
      // "mqtt_broker": mqttBroker,
      // "mqtt_port": mqttPort,
      // "mqtt_force": mqttForce,
      "ads_active": adsEnabled,
      "modbus_active": modbusEnabled,
      "opc_active": true,
      "opc_endpoint": opc_endpoint,
      "opc_user": opc_user === "" ? null : opc_user,
      "opc_pw": opc_pw === "" ? null : opc_pw,
      "security_policy": security_policy,
      "security_mode": security_mode,
      "vnc_ip": vnc_ip,
      "vnc_true_color": VncTrueColor,
      "login": opc_login
    }

    console.log(" sending body", body)
    axios
      .put("/assets/" + id + "/iot_configs", body)
      .then(function (response) {
        // handle success
        console.log(response.status, "/iot_configs");

        notyf.open({
          type: "success",
          message: t("Item \"") + assetData?.title + t("\" updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function handleDelete(e, type_number) {
    e.preventDefault();

    axios
      .delete("/assets/" + id + "/iot_nodes/type_number/" + type_number)
      .then(function (response) {
        console.log(response.status, "Deleted iot_node");
        // handle success
        loadConfigs();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const [showDataPointModal, setShowDataPointModal] = useState(null);
  // const [selectedDataPointId, setSelectedDataPointId] = useState(null);

  function handleTrigger(event, method, action) {
    event?.preventDefault();

    setIsLoadingRemoteCall(true)

    axios
      .post(getGatewayVPNBaseURL() + "/gateways/trigger", {
        "method": method,
        "path": action,
        "gateway_ip": gatewayData?.internal_ip,
        "port": "1880",
        "username": "cap-on_admin",
        "password": "aehek*j+xoj^v2b9"
      }
      )
      .then(function (response) {
        // handle success
        console.log(response.status, `/triggered "${action}"`, response.data);
        setGatewayVPNResponse(response.data)

        if (response.data?.modalView) {
          setShowContentModal(true)
        }

        notyf.open({
          type: response.data?.success ? "success" : "warning",
          message: `${response.data?.message ? response.data?.message : "Success"}`,
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        setIsLoadingRemoteCall(false)

      })
      .catch(function (error) {
        // handle error

        notyf.open({
          type: "warning",
          message: t("An error occured"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        console.log(error);
        setIsLoadingRemoteCall(false)
      });
  }

  function renderUnit(unit) {

    switch (unit) {
      case "°C":
        return i18n.language === "us" ? "°F" : "°C"
      case "bar":
        return i18n.language === "us" ? "PSI" : "bar"
      default:
        return unit || ""
    }
  }

  function formatDataDiff(targetDate) {

    const currentDate = new Date();
    const timeDifference = currentDate - targetDate;

    if (timeDifference <= 1 * 60 * 1000) {
      return t("Just now")
    } else {
      return formatDistanceToNow(targetDate, { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })
    }
  }

  const [PLC_Node_Ids_latest, setPLC_Node_Ids_latest] = useState([]);

  function fetchLatestPLC_Node_ID_Data() {

    // setIsLoadingTwingData(true)

    setPLC_Node_Ids_latest([]);

    axios
      .get(`/assets/${id}/asset_logs/latest`)
      .then(function (response) {
        // handle success
        console.log(response.status, `/asset_logs/latest. Amount: ${response.data?.length}`);
        setPLC_Node_Ids_latest(response.data);  // Adjust according to your API's response structure
        // setIsLoadingTwingData(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error });
        console.error('Failed to fetch data:', error);
        // setIsLoadingTwingData(false)
      });
  };

  const [iter_mode, set_iter_mode] = useState();
  const [showOPCBrowser, setShowOPCBrowser] = useState(false);
  const [showDigialTwinEditor, setShowDigialTwinEditor] = useState(false);

  return (
    <React.Fragment>

      <GatewayVPNContentModal data={gatewayVPNResponse} asset_id={id} show={showContentModal} handleClose={() => { setShowContentModal(false) }} />

      <GatewayOPCUABrowserModal asset_id={id} show={showOPCBrowser} mode={iter_mode} handleClose={() => { setShowOPCBrowser(false) }} />

      <SingleTwinStructureModal asset_id={id} show={showDigialTwinEditor} configData={configData} handleClose={() => {
        setShowDigialTwinEditor(false)
        loadConfigs()
      }} />

      <SingleIoTDataPointModal
        node_data={showDataPointModal}
        show={showDataPointModal}
        isNew={showDataPointModal === "new" ? true : false}
        asset_id={id}
        handleClose={() => {
          loadConfigs();
          setShowDataPointModal(null)
        }} />

      <Helmet title={assetData?.title} />

      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/assets">{t("Assets")}</Breadcrumb.Item>
          <Breadcrumb.Item href={"/assets/" + id}>{t("Asset 360° View")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("IoT data setup")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            {isLoading ? ShowLoadingSpinner() : <h3>{assetData?.title}</h3>}
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button onClick={() => setShowWizard(true)} variant="primary" className="shadow-sm me-1">
              <File className="feather" /> {t("Wizard")}
            </Button> */}
            <Button
              variant="outline-primary"
              className="shadow-sm me-1 mt"
              size="sm"
              onClick={() => navigate("/assets/" + id + "/?focus=digital-twin")}>
              <Wifi className="feather" /> {isMobile ? "" : t("Digital Twin")}
            </Button>
            <Button
              variant="outline-primary"
              className="shadow-sm me-1"
              size="sm"
              onClick={() => navigate("/gateways/" + gatewayData?.id)}>
              <HardDrive className="feather" /> {isMobile ? "" : t("View Gateway")}
            </Button>

            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <EditAssetModal />
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => window.open(("http://" + gatewayData?.internal_ip + ":1880"), '_blank')}>{t("Remote Configuration")}</Dropdown.Item>
                <Dropdown.Item onClick={() => navigator.clipboard.writeText("ssh admin@" + gatewayData?.internal_ip)}>{t("Remote Shell Command")}</Dropdown.Item>
                <Dropdown.Divider />
                <ImportFromTemplateModal type="iot_nodes" />
                <ImportFromTemplateModal type="iot_configs" />
                <Dropdown.Divider />
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>

        {!isLoading && <Row>
          <Col lg="12">
            <div className={"tab Default"}>
              <Tab.Container id="left-tabs-example" defaultActiveKey={"data"} >
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="data">
                      <Activity className="feather me-2" />
                      {isMobile ? "" : t("IoT data setup")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="configuration">
                      <Settings className="feather me-2" />
                      {isMobile ? "" : t("IoT connector configuration")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>

                  <Tab.Pane eventKey="data">
                    {/* {UserDataManager.getInstance().isCapOn() &&  <> */}
                     
                      <Row className="mt-3">
                        <Col>
                          <Card.Header as='h3'>{t("IoT Data Points")}</Card.Header>
                        </Col>
                        <Col xs="auto" className="ms-auto text-end mt-1">
                          <Button
                            variant="outline-primary"
                            className="shadow-sm me-1"
                            size="sm"
                            onClick={(e) => setShowDigialTwinEditor(true)}>
                            {t("Digital Twin Editor")}
                          </Button>
                          <Button variant="primary" size="sm" onClick={() => setShowDataPointModal("new")}>
                            {t("Add data point")}
                          </Button>
                        </Col>
                      </Row>
                        <Row className="mt-3">
                          <Col>
                            <Table responsive hover>
                              <thead>
                                <tr>
                                  <th scope="col">{t("Protokoll")}</th>
                                  <th scope="col">{t("Name")}</th>
                                  <th scope="col">{t("Unit")}</th>
                                  <th scope="col">{t("Reading")}</th>
                                  <th scope="col">{t("Subscribing")}</th>
                                  <th scope="col">{t("Watch Towers")}</th>
                                  <th scope="col">{t("AI-Report")}</th>
                                </tr>
                              </thead>
                              <tbody>{configData?.items?.sort((a, b) => a?.log_type?.technical_name > b?.log_type?.technical_name)?.map((entry, indx) => {
                                return (
                                  <tr key={indx} onClick={e => setShowDataPointModal(entry)} style={{ cursor: "pointer" }} >
                                    <td>
                                      <span className="d-inline-block">
                                        {entry?.connection_protocol === "opc_ua" && <img
                                          src={OPCUA_Logo}
                                          alt="avatar"
                                          width="auto"
                                          height={15}
                                        />}
                                        {entry?.connection_protocol === "mqtt" && <img
                                          src={mqtt_Logo}
                                          alt="avatar"
                                          width="auto"
                                          height={15}
                                        />}
                                        {entry?.connection_protocol === "ads" && <img
                                          src={beckhoff_Logo}
                                          alt="avatar"
                                          width="auto"
                                          height={15}
                                        />}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        <OverlayTrigger
                                          placement='bottom'
                                          overlay={
                                            <Popover>
                                              <Popover.Body>
                                                Technical-Name: <strong>{entry?.log_type?.technical_name || "-"}</strong><br />
                                                Display-Name: <strong>{entry?.log_type?.display_name || "-"}</strong><br /><br />
                                                Technical-ID: <strong>{entry?.log_type?.plc_node_id}</strong>
                                              </Popover.Body>
                                            </Popover>
                                          }
                                        >
                                          <Info className="feather me-2" />
                                        </OverlayTrigger>
                                      </span>
                                      {entry?.log_type?.technical_name || entry?.log_type?.display_name || entry?.log_type?.plc_node_id || "-"}
                                    </td>
                                    <td>{t(entry?.log_type?.unit) || "-"}</td>
                                    <td>{entry?.read_interval
                                      ? entry?.read_interval > 60000
                                        ? ((entry?.read_interval / 60000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 }) + " min")
                                        : ((entry?.read_interval / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " s")
                                      : "-"} </td>
                                    <td>{entry?.subscription_interval
                                      ? entry?.subscription_interval > 60000
                                        ? ((entry?.subscription_interval / 60000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1 }) + " min")
                                        : ((entry?.subscription_interval / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " s")
                                      : "-"}
                                    </td>
                                    <td>{t(entry?.watchtowers?.length) || "-"}</td>
                                    {/* <td>
                                  {entry?.read_interval && <li>{(t("Reading every ") + (entry?.read_interval / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + "s")}</li>}
                                  {entry?.subscription_interval && <li>{t("Subscription interval")}: {(entry?.subscription_interval / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + "s"}</li>}
                                </td> */}
                                    <td>{entry?.ai_report_relevance ? "✓" : "╳"}</td>
                                    <td className="table-action">
                                      <Clipboard alt="Copy" className="align-middle me-2" size={18} style={{ cursor: "pointer" }} onClick={(e) => {
                                        e.stopPropagation();
                                        const escapedPlcNodeId = entry?.log_type?.plc_node_id.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
                                        navigator.clipboard.writeText(escapedPlcNodeId)
                                      }
                                      } />

                                      <Eye alt="Read" className="align-middle me-2" size={18} style={{ cursor: "pointer" }} onClick={(e) => {
                                        e.stopPropagation();
                                        handleTrigger(e, "GET", `read_opc_variable/?nodeId=${encodeURIComponent(entry?.log_type?.plc_node_id)}`)
                                      }
                                      } />

                                      <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
                                        e.stopPropagation();
                                        window.confirm(t("Are you sure you wish to delete this data point?")) && handleDelete(e, entry.type_number)
                                      }
                                      } />
                                    </td>
                                  </tr>
                                )
                              })}</tbody>
                            </Table>
                          </Col>
                        </Row>
                      {/* </>} */}

                    <Row>
                      <Col>
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="1" className="bg-white">
                            <Accordion.Header>{t("Raw Data Monitor")}</Accordion.Header>
                            <Accordion.Body className="py-2" style={{ padding: "40px" }}>
                              <br />
                              <Row>
                                <Col xs="auto">
                                  <div className="d-flex">
                                    <Card.Title tag="h5" className="mb-0">
                                      {t("Raw data (latest of a kind)")}
                                    </Card.Title>
                                  </div>
                                </Col>
                                <Col xs="auto" className="ms-auto text-end">
                                  <Button
                                    variant="outline-primary"
                                    className="shadow-sm me-1"
                                    size="sm"
                                    onClick={() => navigate("/assets/" + id + "/setup")}>
                                    {t("IoT data setup")}
                                  </Button>
                                  <Button
                                    onClick={() => fetchLatestPLC_Node_ID_Data()}
                                    // onClick={() => reloadDigitalTwin()}
                                    variant="outline-primary"
                                    size="sm"
                                    className="shadow-sm">
                                    <RefreshCw className="feather" />
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Table size="sm" >
                                  <thead>
                                    <tr>
                                      <th scope="col">{t("Latest")}</th>
                                      <th scope="col">{t("Name")}</th>
                                      <th scope="col">{t("Value")}</th>
                                      <th scope="col">{t("Unit")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {PLC_Node_Ids_latest?.sort((a, b) => a?.time < b?.time)?.map((item) => {
                                      return <tr
                                        key={item?.plc_node_id}
                                      // onClick={() => {
                                      //   setSelectedNodeIdForWatchTower(item?.plc_node_id)
                                      //   setShowWatchTowerModal(true)
                                      // }}
                                      // style={{ cursor: "pointer" }}
                                      >
                                        <td><strong>{item === null ? '-' : formatDataDiff(parseISO(item?.time))}</strong></td>
                                        <td>
                                          <span>
                                            <OverlayTrigger
                                              placement='bottom'
                                              overlay={
                                                <Popover>
                                                  <Popover.Body>
                                                    Technical-ID:<br />
                                                    <strong>{item?.plc_node_id}</strong>
                                                  </Popover.Body>
                                                </Popover>
                                              }
                                            >
                                              <Info className="feather me-2" />
                                            </OverlayTrigger>
                                          </span>
                                          {item?.technical_name || item?.display_name || item?.plc_node_id || "-"}
                                        </td>
                                        <td>{item?.value || "-"}</td>
                                        <td>{item?.unit ? `(${renderUnit(item?.unit)})` : "n/a"}</td>
                                      </tr>
                                    })}
                                  </tbody>
                                </Table>
                                {/* {PLC_Node_Ids_latest?.map(item => <DataPointCardRaw data={item} />)} */}
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="auto" className="ms-auto text-end">
                        <Button
                          variant="primary"
                          className="shadow-sm me-1 mt-4"
                          onClick={(e) => handleTrigger(e, "GET", "reload_config")}>
                          <Play className="feather" /> {t("Apply changes on gateway")}
                        </Button>
                      </Col>
                    </Row>

                  </Tab.Pane>

                  <Tab.Pane eventKey="configuration" >
                    <Row className="mt-3">
                      <Col>
                        <Card.Header className="mb-3" as='h3'>{t("IT/OT Integration")}</Card.Header>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="d-flex">
                        <Card className="flex-fill" >
                          <Form onSubmit={handleSubmit} style={{ background: palette["gray-100"], padding: "20px" }}>

                            <Row>
                              <div className={"tab tab-vertical pt-3"}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                  <Nav variant="tabs">
                                    <Nav.Item>
                                      <Nav.Link eventKey="first">
                                        <img
                                          src={OPCUA_Logo}
                                          alt="avatar"
                                          width="auto"
                                          className="ms-2"
                                          height={20}
                                        />
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="second">
                                        <img
                                          src={vnc_Logo}
                                          alt="avatar"
                                          width="auto"
                                          className="ms-2"
                                          height={24}
                                        /> {t("VNC")}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="third">
                                        <img
                                          src={mqtt_Logo}
                                          alt="avatar"
                                          width="auto"
                                          className="ms-2"
                                          height={20}
                                        />
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="forth">
                                        <img
                                          src={modbus_Logo}
                                          alt="avatar"
                                          width="auto"
                                          className="ms-2"
                                          height={24}
                                        />
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="fifth">
                                        <img
                                          src={beckhoff_Logo}
                                          alt="avatar"
                                          width="auto"
                                          className="ms-2"
                                          height={15}
                                        />
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                  <Tab.Content style={{ height: '400px', overflowY: 'auto' }}>
                                    <Tab.Pane eventKey="first">
                                      <Row>
                                        <Col md={12}>
                                          <Form.Group className="mb-4">
                                            <Form.Check
                                              type="checkbox"
                                              onChange={() => setOpcuaEnabled(!opcuaEnabled)}
                                              checked={opcuaEnabled}
                                              label={t("Activate OPC UA")}
                                            />
                                          </Form.Group>
                                        </Col>
                                        {opcuaEnabled && <Col md={12}>
                                          <hr />
                                          <Row >
                                            <Col md={12}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>{t("Endpoint")}</Form.Label>
                                                <Form.Control onChange={event => set_opc_endpoint(event.target.value)} defaultValue={configData?.opc_endpoint} placeholder="opc.tcp://" />
                                              </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                              <Form.Group className="mb-3">
                                                <Form.Check
                                                  type="checkbox"
                                                  onChange={() => set_opc_login(!opc_login)}
                                                  checked={opc_login}
                                                  label={t("User login required")}
                                                />
                                              </Form.Group>
                                            </Col>
                                            {opc_login && <>
                                              <Col md={6}>
                                                <Form.Group className="mb-3">
                                                  <Form.Label>{t("User")}</Form.Label>
                                                  <Form.Control onChange={event => opc_user = event.target.value} defaultValue={opc_user} placeholder={t("OPC UA User")} />
                                                </Form.Group>
                                              </Col>
                                              <Col md={6}>
                                                <Form.Group className="mb-3">
                                                  <Form.Label>{t("Password")}</Form.Label>
                                                  <Form.Control type="password" onChange={event => opc_pw = event.target.value} defaultValue={opc_pw} placeholder={t("OPC UA Password")} />
                                                </Form.Group>
                                              </Col>
                                            </>}
                                          </Row>
                                          <Row>
                                            <Col md={6}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>{t("Security Policy")}</Form.Label>
                                                <Form.Select
                                                  name="state"
                                                  defaultValue={configData?.security_policy}
                                                  onChange={event => set_security_policy(event.target.value)}>
                                                  <option value="">{t("-- Select --")}</option>
                                                  <option value={"None"}>{t("None")}</option>
                                                  <option value={"Basic128"} >{t("Basic128")}</option>
                                                  <option value={"Basic192"} >{t("Basic192")}</option>
                                                  <option value={"Basic256"} >{t("Basic256")}</option>
                                                  <option value={"Basic128Rsa15"} >{t("Basic128Rsa15")}</option>
                                                  <option value={"Basic192Rsa15"} >{t("Basic192Rsa15")}</option>
                                                  <option value={"Basic256Rsa15"} >{t("Basic256Rsa15")}</option>
                                                  <option value={"Basic256Sha256"} >{t("Basic256Sha256")}</option>
                                                  <option value={"Aes128_Sha256"} >{t("Aes128_Sha256")}</option>
                                                  <option value={"Aes128_Sha256_RsaOaep"} >{t("Aes128_Sha256_RsaOaep")}</option>
                                                  <option value={"PubSub_Aes128_CTR"} >{t("PubSub_Aes128_CTR")}</option>
                                                  <option value={"PubSub_Aes256_CTR"} >{t("PubSub_Aes256_CTR")}</option>
                                                </Form.Select>
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>{t("Security Mode")}</Form.Label>
                                                <Form.Select
                                                  name="state"
                                                  defaultValue={configData?.security_mode}
                                                  onChange={event => set_security_mode(event.target.value)}>
                                                  <option value="">{t("-- Select --")}</option>
                                                  <option value={"None"} >{t("None")}</option>
                                                  <option value={"Sign"} >{t("Sign")}</option>
                                                  <option value={"Sign&Encyrpt"}>{t("Sign&Encyrpt")}</option>
                                                </Form.Select>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs="auto" className="ms-auto text-end mt-2">
                                              <div className="d-flex align-items-center">
                                                {isLoadingRemoteCall && <div className="me-2">
                                                  <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
                                                </div>}
                                                <Button
                                                  variant="outline-primary"
                                                  className="shadow-sm me-1"
                                                  size="sm"
                                                  onClick={(e) => {
                                                    const pattern = /:\/\/(.*?)(:\d+)?$/; // Regex to extract IP without the port
                                                    const match = opc_endpoint?.match(pattern);
                                                    if (match) {
                                                      const ipAddress = match[1]; // Capture only the IP address
                                                      handleTrigger(e, "GET", `ping_opc/?plc_ip=${ipAddress}`);
                                                    } else {
                                                      console.error('Invalid OPC UA URL');
                                                      // Optionally, enhance error handling, e.g., displaying a user message
                                                    }
                                                  }}>
                                                  <Code className="feather" /> {t("Ping PLC")}
                                                </Button>
                                                <Button
                                                  variant="primary"
                                                  className="shadow-sm me-1"
                                                  size="sm"
                                                  onClick={(e) => handleTrigger(e, "GET", `check_opc/?user=${opc_user}&pw=${opc_pw}&endpoint=${opc_endpoint}&securitypolicy=${security_policy}&securitymode=${security_mode}&useLogin=${opc_login ? "true" : "false"}&nodeId=ns=0;i=2256`)}>
                                                  <Activity className="feather" /> {t("Test OPC connection")}
                                                </Button>
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col xs="auto" className="ms-auto text-end mt-2">
                                              <Button
                                                variant="outline-secondary"
                                                className="shadow-sm me-1"
                                                size="sm"
                                                onClick={(e) => window.confirm("Make sure you \"applied\" all prior changes in order for the OPC browsing to work.") && handleTrigger(e, "GET", "trigger_opc_crawler/?iter_mode=light")}>
                                                {t("Browsing (Light)")}
                                              </Button>
                                              <Button
                                                variant="outline-secondary"
                                                className="shadow-sm me-1"
                                                size="sm"
                                                onClick={(e) => window.confirm("Make sure you \"applied\" all prior changes in order for the OPC browsing to work.") && handleTrigger(e, "GET", "trigger_opc_crawler/?iter_mode=full")}>
                                                {t("Browsing (Full)")}
                                              </Button>
                                              <Button
                                                variant="outline-secondary"
                                                className="shadow-sm me-1"
                                                size="sm"
                                                onClick={() => {
                                                  set_iter_mode("light")
                                                  setShowOPCBrowser(true)
                                                }}>
                                                {t("OPC UA Server Data Results (light)")}
                                              </Button>
                                              <Button
                                                variant="outline-secondary"
                                                className="shadow-sm me-1"
                                                size="sm"
                                                onClick={() => {
                                                  set_iter_mode("full")
                                                  setShowOPCBrowser(true)
                                                }}>
                                                {t("OPC UA Server Data Results")}
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Col>}
                                      </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                      <Row>
                                        <Col md={12}>
                                          <Form.Group className="mb-4">
                                            <Form.Check
                                              type="checkbox"
                                              onChange={() => setVncEnabled(!vncEnabled)}
                                              checked={vncEnabled}
                                              label={t("Activate VNC")}
                                            />
                                          </Form.Group>
                                        </Col>
                                        {vncEnabled && <Row>
                                          <hr />
                                          <Col md={12}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("VNC-IP")}</Form.Label>
                                              <Form.Control onChange={event => vnc_ip = event.target.value} defaultValue={vnc_ip} placeholder={t("IP-Address of the VNC device")} />
                                            </Form.Group>

                                            <Form.Group className="mb-4">
                                              <Form.Check
                                                type="checkbox"
                                                onChange={() => setVncTrueColor(!VncTrueColor)}
                                                checked={VncTrueColor}
                                                label={t("Activate VNC True Color")}
                                              />
                                            </Form.Group>
                                          </Col>

                                        </Row>}
                                      </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                      <Row>
                                        <Col md={12}>
                                          <Form.Group className="mb-4">
                                            <Form.Check
                                              type="checkbox"
                                              onChange={() => setMqttEnabled(!mqttEnabled)}
                                              checked={mqttEnabled}
                                              label={t("Activate MQTT")}
                                            />
                                          </Form.Group>
                                        </Col>
                                        {mqttEnabled && <Col md="12">
                                          <hr />
                                          <Row>
                                            <Col md={6}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>{t("MQTT Broker")}</Form.Label>
                                                <Form.Control onChange={event => mqtt_broker = event.target.value} defaultValue={mqtt_broker} placeholder={t("Broker")} />
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>{t("MQTT Port")}</Form.Label>
                                                <Form.Control onChange={event => mqtt_port = event.target.value} defaultValue={mqtt_port} placeholder={t("Port")} />
                                              </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                              <Form.Group className="mb-3">
                                                <Form.Label>{t("Force MQTT")}</Form.Label>
                                                <Form.Check
                                                  type="switch"
                                                  onChange={() => mqttForce = !mqttForce}
                                                  defaultChecked={mqttForce}
                                                  label={t("enabled")}
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </Col>}
                                      </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="forth">
                                      <Row>
                                        <Col md={12}>
                                          <Form.Group className="mb-4">
                                            <Form.Check
                                              type="checkbox"
                                              onChange={() => setModbusEnabled(!modbusEnabled)}
                                              checked={modbusEnabled}
                                              label={t("Activate Modbus")}
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                      <Row>
                                        <Col md={12}>
                                          <Form.Group className="mb-4">
                                            <Form.Check
                                              type="checkbox"
                                              onChange={() => setADSEnabled(!adsEnabled)}
                                              checked={adsEnabled}
                                              label={t("Activate Beckhoff / ADS / TwinCAT")}
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Tab.Container>
                              </div>
                            </Row>

                            <hr />

                            <Row>
                              <Col xs="auto" className="ms-auto text-end">
                                <Button
                                  variant={"primary"}
                                  className="shadow-sm me-1"
                                  type="submit">
                                  <Save className="feather" /> {t("Save configuration")}
                                </Button>

                                <Button
                                  variant="outline-primary"
                                  className="shadow-sm me-1"
                                  onClick={(e) => handleTrigger(e, "GET", "reload_config")}>
                                  <Play className="feather" /> {t("Apply changes on gateway")}
                                </Button>
                              </Col>
                            </Row>

                          </Form>
                        </Card>
                      </Col>
                    </Row>
                  </Tab.Pane>

                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>}

      </Container >
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AssetDataSetup;
